import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from './../../environments/environment';
import { AppApiService } from './app-api/app-api.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  agency: Observable<any>;
  private agencySubject: BehaviorSubject<any>;
  appCode = environment.appCode;
  appName = environment.appName;
  public currentUser: Observable<User>;
  private currentUserSubject: BehaviorSubject<User>;
  public defaultCheckout: Observable<User>;
  private defaultCheckoutSubject: BehaviorSubject<any>;
  public checkout: Observable<any>;
  private checkoutSubject: BehaviorSubject<any>;
  public currentUserRoles: Observable<any>;
  private currentUserRolesSubject: BehaviorSubject<any>;
  public path: Observable<any>;
  private pathSubject: BehaviorSubject<any>;
  public rolesPermissions: Observable<any>;
  private rolesPermissionsSubject: BehaviorSubject<any>;
  public expiryTime: Observable<any>;
  private expiryTimeSubject: BehaviorSubject<any>;
  // backendUrl: string = 'http://192.168.43.52:8000';
  backendUrl: string = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    public api: AppApiService,

  ) {
    this.checkoutSubject = new BehaviorSubject<any>(this.getLocalStorageItem('checkout', 'object'));
    this.checkout = this.checkoutSubject.asObservable();
    this.currentUserSubject = new BehaviorSubject<User>(this.getLocalStorageItem('currentUser', 'object'));
    this.currentUserRolesSubject = new BehaviorSubject<any>(this.getLocalStorageItem('currentUserRoles', 'object'));
    this.pathSubject = new BehaviorSubject<any>(this.getLocalStorageItem('path', 'object'));
    this.path = this.pathSubject.asObservable();
    this.rolesPermissionsSubject = new BehaviorSubject<any>(this.getLocalStorageItem('rolesPermissions', 'object'));
    this.rolesPermissions = this.rolesPermissionsSubject.asObservable();
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentUserRoles = this.currentUserRolesSubject.asObservable();
    this.expiryTimeSubject = new BehaviorSubject<any>(this.getLocalStorageItem('expiryTime'));
    this.agencySubject = new BehaviorSubject<any>(this.getLocalStorageItem('agency', 'object'));
    this.agency = this.agencySubject.asObservable();
    const currentExpiryTime = this.expiryTimeSubject.value;
    const now = new Date();
    if (now.getTime() > currentExpiryTime) {
      this.logout();
    } else {
      const remaining = currentExpiryTime - now.getTime();
      if (remaining < 2 * 1000 && this.currentUserSubject.value) {
        this.increaseExpiryDate(remaining);
      }
    }
  }

  public get defaultCheckoutValue(): User {
    return this.defaultCheckoutSubject.value;
}

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public get expiryTimeValue(): User {
    return this.expiryTimeSubject.value;
  }
  public get agencyValue(): any {
    return this.agencySubject.value;
  }

  refresh() {
    console.log('refresh');
    this.http.post<any>(this.backendUrl + `/api/auth/refresh`, {}).subscribe(result => {
      console.log('refresh', result);
      // store result details and jwt token in local storage to keep result logged in between page refreshes
      this.setLocalStorageItem('token', result.access_token);
      const sessionTime = new Date().getTime();
      this.setLocalStorageItem('sessionTime', sessionTime);
      return result;
    },
      (error) => {
        //  this.logout(this.router.url);
      }
    );
  }
  login(username: string, password: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'skip': 'true',
      }),
    };
    this.setLocalStorageItem('token', null);
    return this.http.post<any>(this.backendUrl + `/api/auth/login`, { username, password }, httpOptions )
      .pipe(map(result => {
        // console.log(result);
        // store result details and jwt token in local storage to keep result logged in between page refreshes
        this.setLocalStorageItem('token', result.access_token);
        return result;
      }));
  }
  getMe(access_token, settings = {}) {
    // console.log('settings', settings)
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + access_token,
        'skip': 'true',
      }),
    };

    return this.http.post<any>(this.backendUrl + `/api/auth/me`, {}, httpOptions)
      .pipe(
        map(user => {
          const agency = settings['agency']
          if(agency) {
            this.setLocalStorageItem('agency', agency);
          }
          // console.log(user);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.setLocalStorageItem('currentUser', JSON.stringify(user));
          this.setLocalStorageItem('currentUserRoles', JSON.stringify(user.job.roles));
          this.setLocalStorageItem('defaultCheckout', JSON.stringify(user.checkout));

          this.currentUserSubject.next(user);
          this.currentUserRolesSubject.next(user.job.roles);

          console.log('rolesPermissions', user.rolesPermissions);
          this.setLocalStorageItem('rolesPermissions', JSON.stringify(user.rolesPermissions));
          this.rolesPermissionsSubject.next(user.rolesPermissions);

          console.log('path', user.path);
          this.setLocalStorageItem('path', JSON.stringify(user.path));
          this.pathSubject.next(user.path);


          console.log('checkout', user.checkout);
          this.setLocalStorageItem('checkout', JSON.stringify(user.checkout));
          this.checkoutSubject.next(user.checkout);

          // Set expiry time
          const date = new Date();
          const minutes = 5;
          const expiryTime = new Date(date.getTime() + minutes * 60000).getTime();
          this.setLocalStorageItem('expiryTime', expiryTime);
          const sessionTime = new Date().getTime();
          this.setLocalStorageItem('sessionTime', sessionTime);
          this.setLocalStorageItem('sessionStartTime', sessionTime);
          // console.log('expiryTime', expiryTime);

          return user;
        }
        ));
  }
  logout(returnUrl = '') {
    console.log('handle logout')
    // remove user from local0 storage to log user out

    const params = {
      session_duration: this.getSessionStartTime(),
    };
    console.log('params', params);
    this.http.post<any>(this.backendUrl + `/api/auth/logout`, params).subscribe((result) => {
      this.handleLogout();
      this.router.navigate(['/auth/login'], {queryParams: {returnUrl}});
    },
      (error) => {
        this.handleLogout();
        this.router.navigate(['/auth/login'], {queryParams: {returnUrl}});
      }
    );
  }
  handleLogout() {
    this.removeSession();
    this.currentUserSubject.next(null);
    if (this.currentUserRolesSubject) {
      this.currentUserRolesSubject.next(null);
    }
  }
  removeSession() {
    localStorage.removeItem('expiryTime');
    localStorage.removeItem('sessionTime');
    localStorage.removeItem('rolesPermissions');
    localStorage.removeItem('path');
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserRoles');
    localStorage.removeItem('menuItems');
    localStorage.removeItem('settings');
    localStorage.removeItem('settingData');
  }
  updateProfilePhoto(userId, form) {
    this.api.updateUserProfilePhoto(userId, form).subscribe(
      (result: any) => {
        const currentUser = JSON.parse(this.getLocalStorageItem('currentUser'));
        console.log('currentUser', currentUser);
        currentUser.photo = result;
        this.currentUserSubject.next(currentUser);
        this.setLocalStorageItem('currentUser', JSON.stringify(currentUser));

      },
      (error) => {
      },
    );
  }
  updateProfile(userId, profileForm) {
    this.api.updateUser(userId, profileForm).subscribe(
      (result: any) => {
        const currentUser = JSON.parse(this.getLocalStorageItem('currentUser'));
        // console.log('currentUser', currentUser);
        // console.log('result', result);
        // Update user detail
        currentUser.lastname = result.lastname;
        currentUser.firstname = result.firstname;
        currentUser.phone = result.phone;
        currentUser.email = result.email;
        currentUser.description = result.description;
        this.currentUserSubject.next(currentUser);
        this.setLocalStorageItem('currentUser', JSON.stringify(currentUser));

      },
      (error) => {
      },
    );
  }
  // Get params
  setLocalStorageItem(name, enteredValue) {
    const value = {};
    value[this.appName] = {};
    value[this.appName][this.appCode] = enteredValue;
    try {
      localStorage.setItem(name, JSON.stringify(value));
    } catch (e) { }
  }
  getLocalStorageItem(name, type='string'): any {
    let temp = {};
    try {
      temp = JSON.parse(localStorage.getItem(name));
      // console.log('temp ' + name, temp);

    } catch (e) { }
    let value;
    if (temp && temp[this.appName] && this.appCode) {
      value = temp[this.appName][this.appCode];
    }
    if (type == 'object') {
      try {
        value = JSON.parse(value);
      } catch (e) { }
    }

    // console.log('type', typeof value)
    // console.log('value getLocalStorageItem', value);
    return value;
  }

  getSessionStartTime() {
    let val;
    const sessionStartTime = this.getLocalStorageItem('sessionStartTime');
    try {
      const now = new Date().getTime();
      const remaining = now - sessionStartTime;
      val = new Date((remaining / 1000) * 1000).toISOString().substr(11, 8);
    } catch (e) {

    }
    return val;
  }
  getLoginRemainingTime() {
    const currentExpiryTime = this.getLocalStorageItem('expiryTime');
    const now = new Date().getTime();
    const remaining = currentExpiryTime - now;
    if (remaining < 40 * 1000 && this.currentUserSubject.value) {
      this.increaseExpiryDate(remaining);
    }
    let val;
    if (currentExpiryTime && this.currentUserSubject.value) {
      // console.log('this.currentUserSubject.value', currentExpiryTime);
      if (remaining) {
        val = new Date((remaining / 1000) * 1000).toISOString().substr(11, 8);
      } else {
        this.logout();
      }
    }
    return val;
  }
  increaseExpiryDate(remaining) {
    const date = new Date();
    const minutes = 5;
    const expiryTime = new Date(date.getTime() + minutes * 60000 + remaining).getTime();
    this.setLocalStorageItem('expiryTime', expiryTime);
    // console.log('expiryTime', expiryTime);
  }
  getSessionTime() {
    const sessionTime = this.getLocalStorageItem('sessionTime');
    const now = new Date().getTime();
    const remaining = now - sessionTime;
    if (remaining > 45 * 60 * 1000 && this.currentUserSubject.value) {
      this.refresh();
    }
    const val = new Date((remaining / 1000) * 1000).toISOString().substr(11, 8);
    return val;
  }

  setAgency(agency) {
    this.agencySubject.next(agency);
  }
}
