import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ResourceActionService } from '../base/resource-action.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppApiUserService extends ResourceActionService {
  url = '/users';

  userProductBoxesSubject = new Subject<any[]>();
  userProductBoxes: any[];
  emitUserProductBoxesSubject() {
    this.userProductBoxesSubject.next(this.userProductBoxes.slice());
  }
  getUserProductBoxes(id, params = {}) {
    this.http.get(environment.backendUrl + '/api' + this.url + '/' + id +  '/productBoxes', {params: params}).subscribe(
      (userProductBoxes: any) => {
        this.userProductBoxes = userProductBoxes;
        this.emitUserProductBoxesSubject();
      },
      (error) => {
      },
    );
  }
}
