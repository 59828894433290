import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root',
})
export class CrudService {
  url: string;

  constructor(public http: HttpClient) {
  }

  getAll(params = {}): Observable<any> {
    console.log(environment.backendUrl + '/api' + this.url);
    return this.http.get(environment.backendUrl + '/api' + this.url, {params: params});
  }

  getAll2(params = {}): Observable<any> {
    console.log(environment.backendUrl + '/api' + this.url);
    return this.http.get(environment.backendUrl + '/api' + this.url + '/extended', {params: params});
  }

  add(entity): Observable<any> {
    return this.http.post(environment.backendUrl + '/api' + this.url, entity);
  }
  get(id, params = {}): Observable<any> {
    return this.http.get(environment.backendUrl + '/api' + this.url + `/${id}`, {params: params});
  }
  update(id, entity): Observable<any> {
    return this.http.put(environment.backendUrl + '/api' + this.url + `/${id}`, entity);
  }
  update2(id, entity): Observable<any> {
    return this.http.post(environment.backendUrl + '/api' + this.url + `/${id}`, entity);
  }


  delete(id): Observable<any> {
    return this.http.delete(environment.backendUrl + '/api' + this.url + `/${id}`);
  }

  addAll(list): Observable<any> {
    return this.http.post(environment.backendUrl + '/api' + this.url + '/addAll', list);
  }
  import(list): Observable<any> {
    return this.http.post(environment.backendUrl + '/api' + this.url + '/import', list);
  }

  // getFile() {
  //   this.http.get(`${environment.apiUrl}`,{
  //     responseType: 'arraybuffer', headers: headers
  //   }
  //    ).subscribe(response => this.downLoadFile(response, 'application/ms-excel'));
  // }

downLoadFile(data: any, type: string) {
  const blob = new Blob([data], { type: type});
  const url = window.URL.createObjectURL(blob);
  const pwa = window.open(url);
  if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert( 'Please disable your Pop-up blocker and try again.');
  }
}

}
