import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { User } from '../../models/user';
import { Job } from '../../models/job';
@Injectable({
  providedIn: 'root'
})
export class AppApiService {

  backendURL = environment.backendUrl;
  apiURL = this.backendURL + '/api';
  constructor(private http: HttpClient) { }
  /*========================================
    CRUD Methods for consuming RESTful API
  =========================================*/

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  callGet(url): Observable<any> {
    return this.http.get<any>(this.backendURL + '/' +  url)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  // Account type
  getAccountTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + '/accountTypes')
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  // Job
  getJobs(): Observable<Job[]> {
    return this.http.get<Job[]>(this.apiURL + '/jobs')
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getJob(id: number): Observable<Job> {
    return this.http.get<Job>(this.apiURL + '/jobs/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  deleteJob(id: number): Observable<Job> {
    return this.http.delete<Job>(this.apiURL + '/jobs/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  addJob(job: Job): Observable<Job> {
  return this.http.post<Job>(this.apiURL + '/jobs', job)
    .pipe(
      catchError(this.handleError),
    );
  }
  updateJob(id, job: Job): Observable<Job> {
    return this.http.put<Job>(this.apiURL + '/jobs/' + id, job)
      .pipe(
        catchError(this.handleError),
    );
  }
  // Roles
  getRoles(account_type_id: number): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + '/roles/accountType/' + account_type_id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getRole(id: number): Observable<any> {
    return this.http.get<any>(this.apiURL + '/roles/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  updateRole(id: number, rolesPermissions): Observable<any> {
    return this.http.put<any>(this.apiURL + '/jobs/' + id + '/rolesPermissionsUpdate', rolesPermissions)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  // User
  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.apiURL + '/users')
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getUser(id: number): Observable<User> {
    return this.http.get<User>(this.apiURL + '/users/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  deleteUser(id: number): Observable<User> {
    return this.http.delete<User>(this.apiURL + '/users/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  addUser(user: User): Observable<User> {
  return this.http.post<User>(this.apiURL + '/users', user)
    .pipe(
      catchError(this.handleError),
    );
  }

  updateUser(id, user: User): Observable<User> {
    return this.http.put<User>(this.apiURL + '/users/' + id, user)
      .pipe(
        catchError(this.handleError),
    );
  }
  updateUserProfilePhoto(id, form: any): Observable<User> {
    return this.http.post<User>(this.apiURL + '/users/' + id + '/updatePhoto', form)
      .pipe(
        catchError(this.handleError),
    );
  }
  // Permission
  getJobsRolesPermisions(jobId, roleId, httpOptions): Observable<any> {
    return this.http.get<any>(this.apiURL + '/jobs/' + jobId + '/roles/' + roleId + '/permissions', httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getGenders(): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + '/genders')
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }


  // Statistics
  getGeneralCount(params): Observable<any> {
    return this.http.get<any>(this.apiURL + '/statistics/generalCount', {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
      );
  }

  getGeneralCountByMonth(params = {}): Observable<any> {
    return this.http.get<any>(this.apiURL + '/statistics/generalCountByMonth', {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
      );
  }


  getGeneralCountByDay(params = {}): Observable<any> {
    return this.http.get<any>(this.apiURL + '/statistics/generalCountByDay', {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
      );
  }


  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    console.log(errorMessage);
    return throwError(errorMessage);
 }


  //add
  getProductOfCategory (id: number): Observable<any> {
    return this.http.get<any>(this.apiURL + '/products/' + id + '/categories')
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  getProducts(params = {}): Observable<any[]> {
    console.log('params', params);
    return this.http.get<any[]>(this.apiURL + '/products', {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getProduct(id: number): Observable<any> {
    return this.http.get<any>(this.apiURL + '/products/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  deleteProduct(id: number): Observable<any> {
    return this.http.delete<any>(this.apiURL + '/products/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  addProduct(product: any): Observable<any> {
  return this.http.post<any>(this.apiURL + '/products', product)
    .pipe(
      catchError(this.handleError),
    );
  }
  updateProduct(id, product: any): Observable<any> {
    return this.http.put<any>(this.apiURL + '/products/' + id, product)
      .pipe(
        catchError(this.handleError),
      );
  }

  uploadProductImport(productsImport: any[]): Observable<any[]> {
    return this.http.post<any[]>(this.apiURL + '/uploadProductImport', productsImport)
      .pipe(
        catchError(this.handleError),
      );
  }

  // ProductCategory
  getProductCategories(): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + '/productCategories')
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getProductCategory(id: number): Observable<any> {
    return this.http.get<any>(this.apiURL + '/productCategories/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  deleteProductCategory(id: number): Observable<any> {
    return this.http.delete<any>(this.apiURL + '/productCategories/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  addProductCategory(productCategory: any): Observable<any> {
  return this.http.post<any>(this.apiURL + '/productCategories', productCategory)
    .pipe(
      catchError(this.handleError),
    );
  }
  updateProductCategory(id, productCategory: any): Observable<any> {
    return this.http.put<any>(this.apiURL + '/productCategories/' + id, productCategory)
      .pipe(
        catchError(this.handleError),
      );
  }

  uploadProductCategoryImport(productCategorysImport: any[]): Observable<any[]> {
    return this.http.post<any[]>(this.apiURL + '/uploadProductCategoryImport', productCategorysImport)
      .pipe(
        catchError(this.handleError),
      );
  }
  // Provider
  getProductProviders(): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + '/productProviders')
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getProductProvider(id: number): Observable<any> {
    return this.http.get<any>(this.apiURL + '/productProviders/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  deleteProductProvider(id: number): Observable<any> {
    return this.http.delete<any>(this.apiURL + '/productProviders/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  addProductProvider(productProvider: any): Observable<any> {
  return this.http.post<any>(this.apiURL + '/productProviders', productProvider)
    .pipe(
      catchError(this.handleError),
    );
  }
  updateProductProvider(id, productProvider: any): Observable<any> {
    return this.http.put<any>(this.apiURL + '/productProviders/' + id, productProvider)
      .pipe(
        catchError(this.handleError),
      );
  }

  uploadProductProviderImport(productProvidersImport: any[]): Observable<any[]> {
    return this.http.post<any[]>(this.apiURL + '/uploadProductProviderImport', productProvidersImport)
      .pipe(
        catchError(this.handleError),
      );
  }

  // Genereal box
  getGeneralBoxProducts(params = {}): Observable<any[]> {
    console.log(this.apiURL + '/productStocks/generalBoxProducts');
    return this.http.get<any[]>(this.apiURL + '/productStocks/generalBoxProducts', {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  getAllBoxProducts(params = {}): Observable<any[]> {
    // console.log(this.apiURL + '/productBoxProducts/extended');
    return this.http.get<any[]>(this.apiURL + '/productBoxProducts/extended', {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  getGeneralBoxReports(): Observable<any[]> {
    console.log(this.apiURL + '/productStocks/generalBoxReports');
    return this.http.get<any[]>(this.apiURL + '/productStocks/generalBoxReports')
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  // Product stock
  getProductStocks(params = {}): Observable<any[]> {
    // console.log(this.apiURL + '/productStocks');
    return this.http.get<any[]>(this.apiURL + '/productStocks', {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getProductStocksOfDelivery(id): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + '/productDeliveries/' + id + '/productStocks')
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getProductStock(id: number): Observable<any> {
    return this.http.get<any>(this.apiURL + '/productStocks/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  deleteProductStock(id: number): Observable<any> {
    return this.http.delete<any>(this.apiURL + '/productStocks/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  addProductStock(productStock: any): Observable<any> {
  return this.http.post<any>(this.apiURL + '/productStocks', productStock)
    .pipe(
      catchError(this.handleError),
    );
  }
  importProductStock(supplyId, productStocks: any[]): Observable<any> {
    return this.
    http.post<any>(this.apiURL + '/productDeliveries/' + supplyId + '/productStocksImport', productStocks)
    .pipe(
        catchError(this.handleError),
      );
    }
  updateProductStock(id, productStock: any): Observable<any> {
    return this.http.put<any>(this.apiURL + '/productStocks/' + id, productStock)
      .pipe(
        catchError(this.handleError),
      );
  }

  // Product delivery
  getProductDeliveries(): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + '/productDeliveries')
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getProductDeliveriesPending(): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + '/productDeliveries/getPendingDeliveries')
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getProductDelivery(id: number): Observable<any> {
    return this.http.get<any>(this.apiURL + '/productDeliveries/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  deleteProductDelivery(id: number): Observable<any> {
    return this.http.delete<any>(this.apiURL + '/productDeliveries/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  addProductDelivery(productDelivery: any): Observable<any> {
  return this.http.post<any>(this.apiURL + '/productDeliveries', productDelivery)
    .pipe(
      catchError(this.handleError),
    );
  }
  updateProductDelivery(id, productDelivery: any): Observable<any> {
    return this.http.put<any>(this.apiURL + '/productDeliveries/' + id, productDelivery)
      .pipe(
        catchError(this.handleError),
      );
  }

  endProductDelivery(id, params): Observable<any> {
    return this.http.put<any>(this.apiURL + '/productDeliveries/' + id + '/' + 'endProductDelivery', params)
      .pipe(
        catchError(this.handleError),
      );
  }
  //
  getProductBoxReports(params = {}): Observable<any[]> {
    // console.log('params', params);
    return this.http.get<any[]>(this.apiURL + '/productBoxReports', {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  getProductBoxOwnerReports(id, params = {}): Observable<any[]> {
    // console.log('params', params);
    return this.http.get<any[]>(this.apiURL + '/productBoxReports/owner/' + id, {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  getProductBoxProductReports(params = {}): Observable<any[]> {
    // console.log('params', params);
    // console.log(this.apiURL + '/productTransactions/getTotalTransactionByProduct');
    return this.http.get<any[]>(this.apiURL + '/productTransactions/getTotalTransactionByProduct', {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  // ProductBoxUser
  getProductBoxUsers(params = {}): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + '/productBoxes/users', {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  // Prodict box
  getProductBoxProducts(params = {}): Observable<any[]> {
    // console.log('params', params);
    return this.http.get<any[]>(this.apiURL + '/productBoxProducts', {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  getProductOfBox(id, productId): Observable<any[]> {
    console.log(this.apiURL + '/productBoxes/' + id + '/products/' + productId);
    return this.http.get<any[]>(this.apiURL + '/productBoxes/' + id + '/products/' + productId)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  getProductBoxProduct(id): Observable<any> {
    return this.http.get<any>(this.apiURL + '/productBoxProducts/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getProductBoxOwners(params = {}): Observable<any[]> {
    console.log('params', params);
    return this.http.get<any[]>(this.apiURL + '/productBoxes/owners', {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getProductBoxes(params = {}): Observable<any[]> {
    console.log('params', params);
    return this.http.get<any[]>(this.apiURL + '/productBoxes', {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getProductBoxesPending(): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + '/productBoxes/getPendingBoxes')
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getProductBox(id: number): Observable<any> {
    console.log(this.apiURL + '/productBoxes/' + id);
    return this.http.get<any>(this.apiURL + '/productBoxes/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  deleteProductBox(id: number): Observable<any> {
    return this.http.delete<any>(this.apiURL + '/productBoxes/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  addProductBox(productBox: any): Observable<any> {
  return this.http.post<any>(this.apiURL + '/productBoxes', productBox)
    .pipe(
      catchError(this.handleError),
    );
  }
  updateProductBox(id, productBox: any): Observable<any> {
    return this.http.put<any>(this.apiURL + '/productBoxes/' + id, productBox)
      .pipe(
        catchError(this.handleError),
      );
  }
  // Product box
  addProductBoxUsers(productBoxUsers: any): Observable<any> {
    return this.http.post<any>(this.apiURL + '/productBoxes/users', productBoxUsers)
      .pipe(
        catchError(this.handleError),
      );
    }
  deleteProductBoxUsers(params = null): Observable<any> {
    return this.http.post<any>(this.apiURL + '/productBoxes/users/deleteMany', params)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  // Supplies
  addProductSupply(productSupply: any): Observable<any> {
    return this.http.post<any>(this.apiURL + '/productSupplies', productSupply)
      .pipe(
        catchError(this.handleError),
    );
  }

  updateProductSupply(id, productSupply: any): Observable<any> {
    return this.http.put<any>(this.apiURL + '/productSupplies/' + id, productSupply)
      .pipe(
        catchError(this.handleError),
      );
  }
  endProductSupply(id, params): Observable<any> {
    return this.http.put<any>(this.apiURL + '/productSupplies/' + id + '/' + 'endProductSupply', params)
      .pipe(
        catchError(this.handleError),
      );
  }


  getProductSupplies(params = {}): Observable<any[]> {
    console.log('params', params);
    return this.http.get<any[]>(this.apiURL + '/productSupplies', {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getProductSuppliesPending(): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + '/productSupplies/getPendingSupplyes')
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getProductSupply(id: number): Observable<any> {
    return this.http.get<any>(this.apiURL + '/productSupplies/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  deleteProductSupply(id: number): Observable<any> {
    return this.http.delete<any>(this.apiURL + '/productSupplies/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  // Product transaction
  getProductTransactions(params = {}): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + '/productTransactions', {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getProductTransactionsOfSupply(id): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + '/productSupplies/' + id + '/productTransactions')
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
  getProductTransaction(id: number): Observable<any> {
    return this.http.get<any>(this.apiURL + '/productTransactions/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  deleteProductTransaction(id: number): Observable<any> {
    return this.http.delete<any>(this.apiURL + '/productTransactions/' + id)
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  addProductTransaction(productTransaction: any): Observable<any> {
  return this.http.post<any>(this.apiURL + '/productTransactions', productTransaction)
    .pipe(
      catchError(this.handleError),
    );
  }
  importProductTransaction(supplyId, productTransactions: any[]): Observable<any> {
    return this.
    http.post<any>(this.apiURL + '/productSupplies/' + supplyId + '/productTransactionsImport', productTransactions)
    .pipe(
        catchError(this.handleError),
      );
    }
  updateProductTransaction(id, productTransaction: any): Observable<any> {
    return this.http.put<any>(this.apiURL + '/productTransactions/' + id, productTransaction)
      .pipe(
        catchError(this.handleError),
      );
  }

  getGeneralBoxReportProducts(params = {}): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + '/generalBoxReportProducts', {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }

  getProductBoxReportProducts(productBoxId, params = {}): Observable<any[]> {
    return this.http.get<any[]>(this.apiURL + '/productBoxReportProducts/'+ productBoxId, {params: params})
    .pipe(
      retry(1),
      catchError(this.handleError),
    );
  }
}
