import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import * as XLSX from 'xlsx';
import { FormatNumberPipe } from '../pipes/format-number.pipe';
import { NumberStringValuePipe } from '../pipes/number-string-value.pipe';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  dateChoice: any = {
    today: '',
    date: '',
    from_date: '',
    to_date: '',
  };

  fieldTypes: any[] = [
    {
      value: 'text',
      name: 'Texte',
    },
    {
      value: 'date',
      name: 'Date',
    },
    {
      value: 'time',
      name: 'Heure',
    },
    {
      value: 'number',
      name: 'Chiffre',
    },
    {
      value: 'checkbox',
      name: 'Booléen',
    },
    {
      value: 'select',
      name: 'Liste de choix unique',
    },
    {
      value: 'textarea',
      name: 'Champ de saisie',
    },
    {
      value: 'group',
      name: 'Groupe de champ',
    },
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private formatNumber: FormatNumberPipe,
  ) { }


  months: any[] = [
    {
      id: 1,
      name: 'Janvier',
    },
    {
      id: 2,
      name: 'Février',
    },
    {
      id: 3,
      name: 'Mars',
    },
    {
      id: 4,
      name: 'Avril',
    },
    {
      id: 5,
      name: 'Mai',
    },
    {
      id: 6,
      name: 'Juin',
    },
    {
      id: 7,
      name: 'Juillet',
    },
    {
      id: 8,
      name: 'Aout',
    },
    {
      id: 9,
      name: 'Septembre',
    },
    {
      id: 10,
      name: 'Octobre',
    },
    {
      id: 11,
      name: 'Novembre',
    },
    {
      id: 12,
      name: 'Décembre',
    },
  ];






  numberStringConvert(lang, number) {
    number = '' + number + '';
    let nArray = [];
    let result = '';

    result = this.numberStringValue(lang, number);
    for (let i = 0; i < number.length; i++) {
      if (number.charAt(i) === ',' || number.charAt(i) === '.') {
        if (number.charAt(i) === ',') {
          nArray = number.split(',');
          // console.log(number.charAt(i));

        }
        if (number.charAt(i) === '.') {
          nArray = number.split('.');
          // console.log(number.charAt(i));

        }
        result = this.numberStringValue(lang, nArray[0]) + ' virgule ' + this.numberStringValue(lang, nArray[1]);

      }
    }
    return result;
  }
  numberStringValue(lang, number) {
    const words = {
      'NUMBER': {
        // bassis
        '0': {
          '0': '0',
          '1': '1', '2': '2', '3': '3', '4': '4', '5': '5',
          '6': '6', '7': '7', '8': '8', '9': '9', '10': '10',
        },
        // particular
        '1': {
          '0': {
            '1': '11',
            '2': '12',
            '3': '13',
            '4': '14',
            '5': '15',
            '6': '16',
          },
          '1': '10',
          '2': '20',
          '3': '30',
          '4': '40',
          '5': '50',
          '6': '60',
          '7': '70',
          '8': '80',
          '9': '90',
        },
        '2': {
          '1': '10', '2': '100', '3': '1000', '4': '10000', '5': '100000',
          '6': '1000000', '7': '10000000', '8': '100000000', '9': '1000000000', '10': '10000000000',
        },
        '3': {
          '1': '&&',
        },
      },
      'FR': {
        // bassis
        '0': {
          '0': 'zéro',
          '1': 'un', '2': 'deux', '3': 'trois', '4': 'quatre', '5': 'cinq',
          '6': 'six', '7': 'sept', '8': 'huit', '9': 'neuf', '10': 'dix',
        },
        // particular
        '1': {
          '0': {
            '1': 'onze',
            '2': 'douze',
            '3': 'treize',
            '4': 'quatorze',
            '5': 'quinze',
            '6': 'seize',
          },
          '1': 'dix',
          '2': 'vingt',
          '3': 'trente',
          '4': 'quarante',
          '5': 'cinquante',
          '6': 'soixante',
          '7': 'soisante-dix',
          '8': 'quatre-vingts',
          '9': 'quatre-vingt-dix',
        },
        '2': {
          '1': 'dix', '2': 'cent', '3': 'mille', '4': 'dix-mille', '5': 'cent mille',
          '6': 'million', '7': 'dix millions', '8': 'cent millions', '9': 'milliard',
        },
        '3': {
          '1': 'et',
        },
      },
    };
    if (number && typeof number !== 'string') {
      number = number.toString();
    }
    let length = null;
    if (number) {
      length = number.length;
    }
    let number_string = '';

    // Particular cases

    const last = length - 1;
    // console.log('length',length);
    // console.log('last',last);
    for (let i = last; i >= 0; i--) {
      // console.log(i);
      // 1
      if
        (
        i === last
        ||
        i === last && number.charAt(i - 1) !== '1'
      ) {
        // console.log('basis', i+ ' '+last);
        if (last === 0) {
          // console.log('only one');
          number_string = this.basis(lang, words, number.charAt(i)) + ' ' + number_string;
        }
      }
      // 10
      if (
        i === last - 1 && i === 0

      ) {
        number_string = this.two(lang, words, number.charAt(i), number.charAt(i + 1)) + ' ' + number_string;
      }

      // 100
      if (
        // 100
        i === last - 2 && i === 0
      ) {
        // console.log('call three i');
        number_string = this.three(lang, words, number.charAt(i), number.charAt(i + 1), number.charAt(i + 2), '2') + ' ' + number_string;
      }
      if (
        // 1000
        i === last - 3 && i === 0
      ) {
        // console.log('value10000');
        number_string = this.value10000(lang, words, number.charAt(i), number.charAt(i + 1), number.charAt(i + 2), number.charAt(i + 3)) + ' ' + number_string;
      }

      if (
        // 10000
        i === last - 4 && i === 0
      ) {
        // console.log(number.charAt(i) + ' ' +last);
        // console.log('value100000');
        number_string = this.value100000(lang, words, number.charAt(i), number.charAt(i + 1), number.charAt(i + 2), number.charAt(i + 3), number.charAt(i + 4)) + ' ' + number_string;
      }
      if (
        // 100000
        i === last - 5 && i === 0
      ) {
        // console.log(number.charAt(i) + ' ' +last);
        // console.log('number.charAt(i)', number.charAt(i));
        // console.log('value1000000');
        number_string = this.value1000000(lang, words, number.charAt(i), number.charAt(i + 1), number.charAt(i + 2), number.charAt(i + 3), number.charAt(i + 4), number.charAt(i + 5)) + ' ' + number_string;
      }
      if (
        // 100000
        i === last - 6 && i === 0
      ) {
        // console.log(number.charAt(i) + ' ' +last);
        // console.log('value10000000');
        number_string = this.value10000000(lang, words, number.charAt(i), number.charAt(i + 1), number.charAt(i + 2), number.charAt(i + 3), number.charAt(i + 4), number.charAt(i + 5), number.charAt(i + 6)) + ' ' + number_string;
        // console.log(number.charAt(i) + ' '+ number.charAt(i + 1) + ' ' + number.charAt(i + 3) + ' ' + number.charAt(i + 4));
      }

      if (
        // 1000000
        i === last - 7 && i === 0
      ) {
        // console.log(number.charAt(i) + ' ' +last);
        // console.log('value10000000 7');

        number_string = this.value100000000(lang, words, number.charAt(i), number.charAt(i + 1), number.charAt(i + 2), number.charAt(i + 3), number.charAt(i + 4), number.charAt(i + 5), number.charAt(i + 6), number.charAt(i + 7)) + ' ' + number_string;
        // console.log(number.charAt(i) + ' '+ number.charAt(i + 1) + ' ' + number.charAt(i + 3) + ' ' + number.charAt(i + 4));
      }
      if (
        // 10000000
        i === last - 8 && i === 0
      ) {
        // console.log('value100000000 9');

        // console.log(number.charAt(i) + ' ' +last);
        number_string = this.value1000000000(lang, words, number.charAt(i), number.charAt(i + 1), number.charAt(i + 2), number.charAt(i + 3), number.charAt(i + 4), number.charAt(i + 5), number.charAt(i + 6), number.charAt(i + 7), number.charAt(i + 8)) + ' ' + number_string;
        // console.log(number.charAt(i) + ' '+ number.charAt(i + 1) + ' ' + number.charAt(i + 3) + ' ' + number.charAt(i + 4));
      }
      if (
        // 10000000000
        i === last - 9 && i === 0
      ) {
        // console.log('value1000000000000 12');

        // console.log(number.charAt(i) + ' ' +last);
        number_string = this.value10000000000(lang, words, number.charAt(i), number.charAt(i + 1), number.charAt(i + 2), number.charAt(i + 3), number.charAt(i + 4), number.charAt(i + 5), number.charAt(i + 6), number.charAt(i + 7), number.charAt(i + 8), number.charAt(i + 9)) + ' ' + number_string;
        // console.log(number.charAt(i) + ' '+ number.charAt(i + 1) + ' ' + number.charAt(i + 3) + ' ' + number.charAt(i + 4));
      }


    }
    return number_string;
  }
  basis(lang, words, value) {
    // console.log('call bassis');
    return words[lang]['0'][value];
  }
  two(lang, words, currentValue, rigthSideValue) {
    // console.log('call two');
    let word = '';
    if (
      currentValue === '1' && this.stringInArray(rigthSideValue, ['0', '7', '8', '9'])
      ||
      currentValue !== '1' && !this.stringInArray(currentValue, ['7', '9'])
      ||
      this.stringInArray(currentValue, ['7', '9']) && this.stringInArray(rigthSideValue, ['0', '7', '8', '9'])
    ) {
      let first = '';
      if (currentValue !== '0') {
        first = words[lang]['1'][currentValue];
      }
      if (rigthSideValue === '1' && currentValue !== '0') {
        first += ' ' + words[lang]['3']['1'];
      }
      let second = '';
      if (rigthSideValue !== '0') {
        second = words[lang]['0'][rigthSideValue];
      }
      // let third = '';
      // console.log('value bas', currentValue + ' ' + rigthSideValue);
      //
      // if(currentValue !== '1' && stringInArray(rigthSideValue, ['1','2', '3', '4', '5', '6', '7', '8', '9'])){
      //   console.log('value bas');
      //   third += basis(lang, words, rigthSideValue);
      // }
      word += first + ' ' + second;
    } else

      if (currentValue === '1' && this.stringInArray(rigthSideValue, ['1', '2', '3', '4', '5', '6'])) {
        // console.log('else');
        const first1 = this.particular(lang, words, currentValue, rigthSideValue);
        word += first1;
      } else if (this.stringInArray(currentValue, ['7', '9']) && !this.stringInArray(rigthSideValue, ['0'])) {
        let first = '';
        first = words[lang]['1'][currentValue - 1];
        // if(rigthSideValue === '1'){
        //   first += ' ' + words[lang]['3']['1'];
        // }
        let second = '';
        if (this.stringInArray(rigthSideValue, ['1', '2', '3', '4', '5', '6'])) {
          // console.log('else');
          second = this.particular(lang, words, '1', rigthSideValue);
        }
        word += first + ' ' + second;
      }

    // console.log('two', word);
    return word;
  }
  three(lang, words, currentValue, rigthSideValue, rigthSidesRightSideValue, position) {
    // console.log('call three');
    let first = '';
    if (currentValue !== '1' && currentValue !== '0') {
      first += words[lang]['0'][currentValue] + ' ';
    }
    if (currentValue !== '0') {
      first += words[lang]['2'][position];
      // console.log('three first', first);
    }
    let second = '';
    second += this.two(lang, words, rigthSideValue, rigthSidesRightSideValue);
    const word = first + ' ' + second;
    // console.log('three', three);
    return word;
  }
  value10000(lang, words, currentValue, rigthSideValue, secondRigthSideValue, thirdRigthSideValue) {
    let name = '';
    if (currentValue !== '0' && currentValue !== '1') {
      name += words[lang]['0'][currentValue] + ' ';
    }
    let first = '';
    if (currentValue !== '0') {
      first += words[lang]['2'][3];
    }
    let third = '';
    third += this.three(lang, words, rigthSideValue, secondRigthSideValue, thirdRigthSideValue, 2);
    let word = '';
    word += name + first + ' ' + third;
    // console.log('value10000', word);
    return word;
  }
  value100000(lang, words, currentValue, rigthSideValue, rigthSidesRightSideValue, thirdRightSideValue, fourthRightSideValue) {
    let first = '';
    first += this.two(lang, words, currentValue, rigthSideValue);
    // first += two(lang, words, currentValue, rigthSideValue);
    if (currentValue !== '0') {
      first += ' ' + words[lang]['2'][3];
    }
    let second = '';
    second = this.three(lang, words, rigthSidesRightSideValue, thirdRightSideValue, fourthRightSideValue, 2);
    const word = first + ' ' + second;
    // console.log('value100000', second);
    return word;
  }
  value1000000(lang, words, currentValue, rigthSideValue, rigthSidesRightSideValue, thirdRightSideValue, fourthRightSideValue, fiftRightSideValue) {
    let first = '';
    first += this.three(lang, words, currentValue, rigthSideValue, rigthSidesRightSideValue, 2) + ' ';
    // Ne pas affifer "mille" s'il y'a 0, 1
    // this.stringInArray(rigthSideValue, ['1', '2', '3', '4', '5', '6'])
    if (rigthSidesRightSideValue !== '0' || (currentValue !== '0') && (this.stringInArray(rigthSideValue, ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'])) ) {
      first += words[lang]['2']['3'];
    }
    first += ' ';
    // console.log('value1000000'+thirdRightSideValue + ' ' + fourthRightSideValue);
    first += this.three(lang, words, thirdRightSideValue, fourthRightSideValue, fiftRightSideValue, 2);
    const word = first;
    return word;
  }
  // 97089009
  value10000000(lang, words, currentValue, rigthSideValue, rigthSidesRightSideValue, thirdRightSideValue, fourthRightSideValue, fiftRightSideValue, sixthRightSideValue) {
    let first = '';
    if (currentValue !== '0') {
      first += words[lang]['0'][currentValue] + ' ';
      first += words[lang]['2'][6] + ' ';
    }
    first += this.value1000000(lang, words, rigthSideValue, rigthSidesRightSideValue, thirdRightSideValue, fourthRightSideValue, fiftRightSideValue, sixthRightSideValue);
    let word = first;
    return word;
  }
  value100000000(lang, words, currentValue, rigthSideValue, rigthSidesRightSideValue, thirdRightSideValue, fourthRightSideValue, fiftRightSideValue, sixthRightSideValue, seventhRightSideValue) {
    let first = '';
    if (currentValue !== '0') {
      first += this.two(lang, words, currentValue, rigthSideValue) + ' ';
      first += words[lang]['2'][6] + ' ';
    }
    first += this.value1000000(lang, words, rigthSidesRightSideValue, thirdRightSideValue, fourthRightSideValue, fiftRightSideValue, sixthRightSideValue, seventhRightSideValue);
    var word = first;
    return word;
  }
  value1000000000(lang, words, currentValue, rigthSideValue, rigthSidesRightSideValue, thirdRightSideValue, fourthRightSideValue, fiftRightSideValue, sixthRightSideValue, seventhRightSideValue, eighthRightSideValue) {
    let first = '';
    first += this.two(lang, words, currentValue, rigthSideValue) + ' ';
    if (currentValue !== '0') {
      first += words[lang]['2'][6] + ' ';
    }
    first += this.value1000000(lang, words, rigthSidesRightSideValue, thirdRightSideValue, fourthRightSideValue, fiftRightSideValue, sixthRightSideValue, seventhRightSideValue);

    let word = first;
    return word;
  }
  value10000000000(lang, words, currentValue, rigthSideValue, rigthSidesRightSideValue, thirdRightSideValue, fourthRightSideValue, fiftRightSideValue, sixthRightSideValue, seventhRightSideValue, eighthRightSideValue, ninethRightSideValue) {
    let first = '';
    first += words[lang]['0'][currentValue] + ' ';
    if (currentValue !== '0') {
      first += words[lang]['2'][9] + ' ';
    }
    first += this.value1000000000(lang, words, rigthSideValue, rigthSidesRightSideValue, thirdRightSideValue, fourthRightSideValue, fiftRightSideValue, sixthRightSideValue, seventhRightSideValue, eighthRightSideValue, ninethRightSideValue);

    let word = first;
    return word;
  }
  particular(lang, words, currentValue, rigthSideValue) {
    let JSONWordArray = JSON.stringify(words[lang]['1']['0']);
    // console.log('particular JSONWordArray', JSONWordArray);

    let wordArray = JSON.parse(JSONWordArray);
    JSONWordArray = JSON.stringify(wordArray);
    wordArray = JSON.parse(JSONWordArray);
    // console.log('particular', wordArray[rigthSideValue]);
    return wordArray[rigthSideValue];
  }
  stringInArray(string, array) {
    for (let x = 0; x < array.length; x++) {
      if (array[x] === string) {
        return true;
      }
    }
    return false;
  }








  // Protect app routes
  getAppRoutes(): any[] {
    let roles: any[];
    if (localStorage.getItem('menuItems')) {

      try {
        const storedItems = JSON.parse(localStorage.getItem('menuItems'));
        return storedItems;
      } catch (e) {}

    }
    this.authenticationService.currentUserRoles.subscribe(x => roles = x);
    // console.log(roles);
    const computedItems: any[] = [
      {
        name: 'Accueil',
        url: '/app/home',
        icon: 'icon-home',
      },
    ];
    const rPItems: any[] = [
      {
        name: 'Tableau de bord',
        icon: 'icon-speedometer',
        url: '/app/dashboard',
        role: 'DASHBOARD',
        permission: 'R',
      },
      {
        name: 'Journal',
        icon: 'fa fa-list ',
        url: '/app/app-logs',
        role: 'APP_LOG',
        permission: 'R',
      },
      {
        title: true,
        name: 'FACTURATION',
        roles: [
          'INVOICE',
          // 'RECEIPT'
        ],
      },
      {
        name: 'Facture',
        url: '/app/invoices/index',
        icon: 'fa fa-users',
        children: [
          {
            name: 'Ajouter',
            url: '/app/invoices/add',
            icon: 'fa fa-plus',
            role: 'INVOICE',
            permission: 'C',
          },
          // {
          //   name: 'En attente ',
          //   url: '/app/invoices/index/pending',
          //   icon: 'fa fa-list',
          //   role: 'INVOICE',
          //   permission: 'R',
          // },
          // {
          //   name: 'Payee ',
          //   url: '/app/invoices/index/paid',
          //   icon: 'fa fa-list',
          //   role: 'INVOICE',
          //   permission: 'R',
          // },
          // {
          //   name: 'Toute les fact... ',
          //   url: '/app/invoices/index',
          //   icon: 'fa fa-list',
          //   role: 'INVOICE',
          //   permission: 'R',
          // },
          {
            name: 'Liste ',
            url: '/app/invoices/index',
            icon: 'fa fa-list',
            role: 'INVOICE',
            permission: 'R',
          },
        ]
      },
      {
        name: 'Recu',
        url: '/app/receipts/index',
        icon: 'fa fa-users',
        children: [
          /*
          {
            name: 'Ajouter',
            url: '/app/receipts/add',
            icon: 'fa fa-plus',
            role: 'RECEIPT',
            permission: 'C',
          },
          */
          {
            name: 'Recus factures',
            url: '/app/receipts/index',
            icon: 'fa fa-list',
            role: 'RECEIPT',
            permission: 'R',
          },
          {
            name: 'Recus avances',
            url: '/app/receipts/advances/index',
            icon: 'fa fa-list',
            role: 'RECEIPT',
            permission: 'R',
          },
        ]
      },
      {
        name: 'Avance',
        url: '/app/invoices/advances/index',
        icon: 'fa fa-users',
        children: [
          /*
          {
            name: 'Ajouter',
            url: '/app/invoiceAdvances/add',
            icon: 'fa fa-plus',
            role: 'RECEIPT',
            permission: 'C',
          },
          */
          {
            name: 'Liste ',
            url: '/app/invoices/advances/index',
            icon: 'fa fa-list',
            role: 'RECEIPT',
            permission: 'R',
          },
        ]
      },
      {
        title: true,
        name: 'ENROLEMENT',
        roles: [
          'CUSTOMER',
        ],
      },
      {
        name: 'Clients',
        url: '/app/customers/index',
        icon: 'fa fa-users',
        children: [
          {
            name: 'Liste',
            url: '/app/customers/index',
            icon: 'fa fa-list',
            role: 'CUSTOMER',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/customers/add',
            icon: 'fa fa-plus',
            role: 'CUSTOMER',
            permission: 'C',
          },
        ]
      },

      {
        title: true,
        name: 'TRESORERIE',
        roles: [
          'TRANSACTION',
        ],
      },
      {
        name: 'Actions Tresor..',
        url: '/app/availabilityAccounts/index',
        icon: 'fa fa-money ',
        children: [
          /*
          {
            name: 'Initialisation',
            url: '/app/availabilityAccounts/actions/initialization',
            icon: 'fa fa-cogs',
            role: 'AVAILABILITY_ACCOUNT',
            permission: 'R',
          },
          {
            name: 'Encaisement',
            url: '/app/availabilityAccounts/actions/cashIns/add',
            icon: 'fa fa-cogs',
            role: 'CASH_ACTION',
            permission: 'C_IN',
          },
          {
            name: 'Decaisement',
            url: '/app/availabilityAccounts/actions/cashOuts/add',
            icon: 'fa fa-cogs',
            role: 'CASH_ACTION',
            permission: 'C_OUT',
          },
          {
            name: 'Mouvement entree',
            url: '/app/availabilityAccounts/actions/transactions/in',
            icon: 'fa fa-cogs',
            role: 'AVAILABILITY_ACCOUNT_TRANSACTION',
            permission: 'R',
          },
          {
            name: 'Mouvement sortie',
            url: '/app/availabilityAccounts/actions/transactions/out',
            icon: 'fa fa-cogs',
            role: 'AVAILABILITY_ACCOUNT_TRANSACTION',
            permission: 'R',
          },
          */
          {
            name: 'Mouvenments',
            url: '/app/transactions/index',
            icon: 'fa fa-cogs',
            role: 'AVAILABILITY_ACCOUNT',
            permission: 'R',
          },
          {
            name: 'Historique cloture',
            url: '/app/availabilityAccounts/reports/index',
            icon: 'fa fa-cogs',
            role: 'AVAILABILITY_ACCOUNT_REPORT',
            permission: 'R',
          },
        ]
      },

      {
        name: 'Initialisation',
        url: '/app/availabilityAccounts/actions/initialization',
        icon: 'fa fa-cogs',
        children: [
          {
            name: 'Liste',
            url: '/app/availabilityAccounts/actions/initialization/list',
            icon: 'fa fa-list',
            role: 'AVAILABILITY_ACCOUNT',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/availabilityAccounts/actions/initialization',
            icon: 'fa fa-plus',
            role: 'AVAILABILITY_ACCOUNT',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Encaisement',
        url: '/app/availabilityAccounts/actions/cashIns',
        icon: 'fa fa-cogs',
        children: [
          {
            name: 'Liste',
            url: '/app/availabilityAccounts/actions/cashIns/index',
            icon: 'fa fa-list',
            role: 'CASH_ACTION',
            permission: 'C_IN',
          },
          {
            name: 'Ajouter',
            url: '/app/availabilityAccounts/actions/cashIns/add',
            icon: 'fa fa-plus',
            role: 'CASH_ACTION',
            permission: 'C_IN',
          },
        ]
      },
      {
        name: 'Decaisement',
        url: '/app/availabilityAccounts/actions/cashOuts',
        icon: 'fa fa-cogs',
        children: [
          {
            name: 'Liste',
            url: '/app/availabilityAccounts/actions/cashOuts/index',
            icon: 'fa fa-list',
            role: 'CASH_ACTION',
            permission: 'C_OUT',
          },
          {
            name: 'Ajouter',
            url: '/app/availabilityAccounts/actions/cashOuts/add',
            icon: 'fa fa-plus',
            role: 'CASH_ACTION',
            permission: 'C_OUT',
          },
        ]
      },
      {
        name: 'Mouvement entrée',
        url: '/app/availabilityAccounts/actions/transactions/in',
        icon: 'fa fa-cogs',
        children: [
          {
            name: 'Liste',
            // url: '/app/availabilityAccounts/actions/transactions/in',
            url: '/app/availabilityAccounts/actions/transactions/in/list/IN',
            icon: 'fa fa-list',
            role: 'AVAILABILITY_ACCOUNT_TRANSACTION',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/availabilityAccounts/actions/transactions/in',
            icon: 'fa fa-plus',
            role: 'AVAILABILITY_ACCOUNT_TRANSACTION',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Mouvement sortie',
        url: '/app/availabilityAccounts/actions/transactions/out',
        icon: 'fa fa-cogs',
        children: [
          {
            name: 'Liste',
            // url: '/app/availabilityAccounts/actions/transactions/out',
            url: '/app/availabilityAccounts/actions/transactions/out/list/OUT',
            icon: 'fa fa-list',
            role: 'AVAILABILITY_ACCOUNT_TRANSACTION',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/availabilityAccounts/actions/transactions/out',
            icon: 'fa fa-plus',
            role: 'AVAILABILITY_ACCOUNT_TRANSACTION',
            permission: 'C',
          },
        ]
      },


      {
        name: 'Paiements de gain',
        url: '/app/payment-types/index',
        icon: 'fa fa-money ',
        children: [
          {
            name: 'Actions',
            url: '/app/payments/action',
            icon: 'fa fa-list',
            role: 'PAYMENT',
            permission: 'R',
          },
          {
            name: 'Paiement',
            url: '/app/payments/make-payment',
            icon: 'fa fa-list',
            role: 'PAYMENT',
            permission: 'R',
          },
        ]
      },
      {
        title: true,
        name: 'Gestion produit',
        roles: [
          'PRODUCT',
        ],
      },
      {
        name: 'Tags',
        url: '/app/tags/index',
        icon: 'fa fa-tag',
        children: [
          {
            name: 'Liste',
            url: '/app/tags/index',
            icon: 'fa fa-list',
            role: 'TAG',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/tags/add',
            icon: 'fa fa-plus',
            role: 'TAG',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Categories tags',
        url: '/app/tags/categories/index',
        icon: 'fa fa-tag',
        children: [
          {
            name: 'Liste',
            url: '/app/tags/categories/index',
            icon: 'fa fa-list',
            role: 'TAG',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/tags/categories/add',
            icon: 'fa fa-plus',
            role: 'TAG',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Produits',
        url: '/app/products/index',
        icon: 'fa fa-product-hunt',
        children: [
          {
            name: 'Liste',
            url: '/app/products/index',
            icon: 'fa fa-list',
            role: 'PRODUCT',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/products/add',
            icon: 'fa fa-plus',
            role: 'PRODUCT',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Fournisseur',
        url: '/app/products/providers/index',
        icon: 'fa fa-money ',
        children: [
          {
            name: 'Liste',
            url: '/app/products/providers/index',
            icon: 'fa fa-list',
            role: 'PRODUCT_PROVIDER',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/products/providers/add',
            icon: 'fa fa-plus',
            role: 'PRODUCT_PROVIDER',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Type produits',
        url: '/app/products/types/index',
        icon: 'fa fa-product-hunt',
        children: [
          {
            name: 'Liste',
            url: '/app/products/types/index',
            icon: 'fa fa-list',
            role: 'PRODUCT_TYPE',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/products/types/add',
            icon: 'fa fa-plus',
            role: 'PRODUCT_TYPE',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Gamme produits',
        url: '/app/products/categories/index',
        icon: 'fa fa-product-hunt',
        children: [
          {
            name: 'Liste',
            url: '/app/products/categories/hierarchy/1/1/index',
            // url: '/app/products/categories/index',
            routeParams: {hierarchy:1},
            icon: 'fa fa-list',
            role: 'PRODUCT_CATEGORY',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/products/categories/hierarchy/1/1/add',
            // url: '/app/products/categories/add',
            routeParams: {hierarchy:1},
            icon: 'fa fa-plus',
            role: 'PRODUCT_CATEGORY',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Categorie produits',
        url: '/app/products/categories/index',
        icon: 'fa fa-product-hunt',
        children: [
          {
            name: 'Liste',
            url: '/app/products/categories/hierarchy/2/2/index',
            // url: '/app/products/categories/index',
            routeParams: {hierarchy:1},
            icon: 'fa fa-list',
            role: 'PRODUCT_CATEGORY',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/products/categories/hierarchy/2/2/add',
            // url: '/app/products/categories/add',
            routeParams: {hierarchy:1},
            icon: 'fa fa-plus',
            role: 'PRODUCT_CATEGORY',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Groupe produits',
        url: '/app/products/categories/index',
        icon: 'fa fa-product-hunt',
        children: [
          {
            name: 'Liste',
            url: '/app/products/categories/hierarchy/3/3/index',
            // url: '/app/products/categories/index',
            routeParams: {hierarchy:1},
            icon: 'fa fa-list',
            role: 'PRODUCT_CATEGORY',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/products/categories/hierarchy/3/3/add',
            // url: '/app/products/categories/add',
            routeParams: {hierarchy:1},
            icon: 'fa fa-plus',
            role: 'PRODUCT_CATEGORY',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Marques',
        url: '/app/brands/index',
        icon: 'fa fa-product-hunt',
        children: [
          {
            name: 'Liste',
            url: '/app/brands/index',
            icon: 'fa fa-list',
            role: 'BRAND',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/brands/add',
            icon: 'fa fa-plus',
            role: 'BRAND',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Proposition prod..',
        url: '/app/products/propositions/index',
        icon: 'fa fa-product-hunt',
        children: [
          {
            name: 'Liste',
            url: '/app/products/propositions/index',
            icon: 'fa fa-list',
            role: 'PRODUCT_PROPOSITION',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/products/propositions/add',
            icon: 'fa fa-plus',
            role: 'PRODUCT_PROPOSITION',
            permission: 'C',
          },
        ]
      },
      {
        title: true,
        name: 'STOCK PRODUIT',
        roles: [
          'PRODUCT_COMMAND',
        ],
      },

      {
        name: 'Stock general',
        url: '/app/products',
        icon: 'fa fa-product-hunt',
        children: [
          {
            name: 'Produit',
            url: '/app/products/boxes/general/products',
            icon: 'fa fa-list',
            role: 'PRODUCT_UNIT',
            permission: 'R',
          },
          {
            name: 'Commande',
            url: '/app/products/providers/commands/index',
            icon: 'fa fa-list',
            role: 'PRODUCT_PROVIDER_COMMAND',
            permission: 'R',
          },
          {
            name: 'Livraison',
            url: '/app/products/providers/deliveries/index',
            icon: 'fa fa-plus',
            role: 'PRODUCT_PROVIDER_DELIVERY',
            permission: 'R',
          },
          {
            name: 'Ravitaillement',
            url: '/app/products/boxes/general/supplies/index',
            icon: 'fa fa-plus',
            role: 'PRODUCT_SUPPLY',
            permission: 'R',
          },
          {
            name: 'Rapport',
            url: '/app/products/boxes/general/reports/index',
            icon: 'fa fa-plus',
            role: 'PRODUCT_BOX',
            permission: 'SHOW_GENERAL_BOX_PRODUCT_REPORT',
          },
        ]
      },
      {
        name: 'Sous stock',
        url: '/app/products/boxes/index',
        icon: 'fa fa-product-hunt',
        children: [
          {
            name: 'Liste',
            url: '/app/products/boxes/index',
            icon: 'fa fa-list',
            role: 'PRODUCT_BOX',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/products/boxes/add',
            icon: 'fa fa-plus',
            role: 'PRODUCT_BOX',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Unite produits',
        url: '/app/products/units/index',
        icon: 'fa fa-product-hunt',
        children: [
          {
            name: 'Liste',
            url: '/app/products/units/index',
            icon: 'fa fa-list',
            role: 'PRODUCT_UNIT',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/products/units/add',
            icon: 'fa fa-plus',
            role: 'PRODUCT_UNIT',
            permission: 'C',
          },
        ]
      },
      {
        title: true,
        name: 'COMMANDE CLIENT',
        roles: [
          'PRODUCT_COMMAND',
        ],
      },
      {
        name: 'Commandes',
        url: '/app/products/commands/index',
        icon: 'fa fa-product-hunt',
        children: [
          {
            name: 'Liste',
            url: '/app/products/commands/index',
            icon: 'fa fa-list',
            role: 'PRODUCT_COMMAND',
            permission: 'R',
          },
          // {
          //   name: 'Commande livree',
          //   url: '/app/products/commands/index',
          //   icon: 'fa fa-list',
          //   role: 'PRODUCT_COMMAND',
          //   permission: 'R',
          // },
          {
            name: 'Ajouter',
            url: '/app/products/commands/add',
            icon: 'fa fa-plus',
            role: 'PRODUCT_COMMAND',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Livraisons',
        url: '/app/products/deliveries/index',
        icon: 'fa fa-product-hunt',
        children: [
          {
            name: 'Liste',
            url: '/app/products/deliveries/index',
            icon: 'fa fa-list',
            role: 'PRODUCT_DELIVERY',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/products/deliveries/add',
            icon: 'fa fa-plus',
            role: 'PRODUCT_DELIVERY',
            permission: 'C',
          },
          // {
          //   name: 'Livraison en att..',
          //   url: '/app/products/deliveries/index',
          //   icon: 'fa fa-list',
          //   role: 'PRODUCT_DELIVERY',
          //   permission: 'R',
          // },
        ]
      },
      {
        title: true,
        name: 'DONNEES DE REF',
        roles: [
          'PRODUCT',
        ],
      },
      {
        name: 'Promotion',
        url: '/app/products/promotions/index',
        icon: 'fa fa-product-hunt',
        children: [
          {
            name: 'Liste',
            url: '/app/products/promotions/index',
            icon: 'fa fa-list',
            role: 'PRODUCT_PROMOTION',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/products/promotions/add',
            icon: 'fa fa-plus',
            role: 'PRODUCT_PROMOTION',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Mode de paiement',
        url: '/app/payment-types/index',
        icon: 'fa fa-book ',
        children: [
          {
            name: 'Liste',
            url: '/app/payment-types/index',
            icon: 'fa fa-list',
            role: 'PAYMENT_TYPE',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/payment-types/add',
            icon: 'fa fa-plus',
            role: 'PAYMENT_TYPE',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Devise',
        url: '/app/currencies/index',
        icon: 'fa fa-money ',
        children: [
          {
            name: 'Liste',
            url: '/app/currencies/index',
            icon: 'fa fa-list',
            role: 'CURRENCY',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/currencies/add',
            icon: 'fa fa-plus',
            role: 'CURRENCY',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Banque',
        url: '/app/banks/index',
        icon: 'fa fa-book ',
        children: [
          {
            name: 'Liste',
            url: '/app/banks/index',
            icon: 'fa fa-list',
            role: 'BANK',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/banks/add',
            icon: 'fa fa-plus',
            role: 'BANK',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Compte de dispo...',
        url: '/app/availabilityAccounts/index',
        icon: 'fa fa-money ',
        children: [
          {
            name: 'Liste',
            url: '/app/availabilityAccounts/index',
            icon: 'fa fa-list',
            role: 'AVAILABILITY_ACCOUNT',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/availabilityAccounts/add',
            icon: 'fa fa-plus',
            role: 'AVAILABILITY_ACCOUNT',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Categorie dep...',
        url: '/app/spents/categories/index',
        icon: 'fa fa-money ',
        children: [
          {
            name: 'Liste',
            url: '/app/spents/categories/index',
            icon: 'fa fa-list',
            role: 'SPENT_CATEGORY',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/spents/categories/add',
            icon: 'fa fa-plus',
            role: 'SPENT_CATEGORY',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Cat. livraisons fourn',
        url: '/app/products/deliveries/categories/index',
        icon: 'fa fa-product-hunt',
        children: [
          {
            name: 'Liste',
            url: '/app/products/providers/deliveries/categories/index',
            icon: 'fa fa-plus',
            role: 'PRODUCT_PROVIDER_DELIVERY_CATEGORY',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/products/providers/deliveries/categories/add',
            icon: 'fa fa-plus',
            role: 'PRODUCT_PROVIDER_DELIVERY_CATEGORY',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Cat. livr. client',
        url: '/app/products/deliveries/categories/index',
        icon: 'fa fa-product-hunt',
        children: [
          {
            name: 'Liste',
            url: '/app/products/deliveries/categories/index',
            routeParams: {hierarchy:1},
            icon: 'fa fa-list',
            role: 'PRODUCT_DELIVERY_CATEGORY',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/products/deliveries/categories/add',
            routeParams: {hierarchy:1},
            icon: 'fa fa-plus',
            role: 'PRODUCT_DELIVERY_CATEGORY',
            permission: 'C',
          },
        ]
      },

      {
        name: 'Type de document',
        url: '/app/documents/types/index',
        icon: 'fa fa-book ',
        children: [
          {
            name: 'Liste',
            url: '/app/documents/types/index',
            icon: 'fa fa-list',
            role: 'DOCUMENT_TYPE',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/documents/types/add',
            icon: 'fa fa-plus',
            role: 'DOCUMENT_TYPE',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Document',
        url: '/app/documents/index',
        icon: 'fa fa-book ',
        children: [
          {
            name: 'Liste',
            url: '/app/documents/index',
            icon: 'fa fa-list',
            role: 'DOCUMENT_TYPE',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/documents/add',
            icon: 'fa fa-plus',
            role: 'DOCUMENT_TYPE',
            permission: 'C',
          },
        ]
      },
      {
        title: true,
        name: 'DEPARTEMENT',
        roles: [
          'DEPARTMENT',
        ],
      },
      {
        name: 'Departement',
        url: '/app/departments/index',
        icon: 'fa fa-book',
        children: [
          {
            name: 'Liste',
            url: '/app/departments/index',
            icon: 'fa fa-list',
            role: 'DEPARTMENT',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/departments/add',
            icon: 'fa fa-plus',
            role: 'DEPARTMENT',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Services',
        url: '/app/departments/services/index',
        icon: 'fa fa-book',
        children: [
          {
            name: 'Liste',
            url: '/app/departments/services/index',
            icon: 'fa fa-list',
            role: 'SERVICE',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/departments/services/add',
            icon: 'fa fa-plus',
            role: 'SERVICE',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Agence',
        url: '/app/agencies/index',
        icon: 'fa fa-book ',
        children: [
          {
            name: 'Liste',
            url: '/app/agencies/index',
            icon: 'fa fa-list',
            role: 'AGENCY',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/agencies/add',
            icon: 'fa fa-plus',
            role: 'AGENCY',
            permission: 'C',
          },
        ]
      },
      {
        title: true,
        name: 'ADMINISTRATION',
        roles: [
          'SETTING',
        ]
      },
      {
        name: 'Utilisateurs',
        url: '/app/security/users',
        icon: 'fa fa-user',
        children: [
          {
            name: 'Liste',
            url: '/app/security/users',
            icon: 'fa fa-list',
            role: 'USER',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/security/users/add',
            icon: 'fa fa-plus',
            role: 'USER',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Postes',
        url: '/app/security/jobs',
        icon: 'icon-puzzle',
        children: [
          {
            name: 'Liste',
            url: '/app/security/jobs',
            icon: 'fa fa-list',
            role: 'JOB',
            permission: 'R',
          },
          {
            name: 'Ajouter',
            url: '/app/security/jobs/add',
            icon: 'fa fa-plus',
            role: 'JOB',
            permission: 'C',
          },
        ]
      },
      {
        name: 'Parametre',
        url: '/app/settings',
        icon: 'fa fa-cogs',
        children: [
          {
            name: 'L \'entreprise',
            url: '/app/settings/enterprise',
            icon: 'fa fa-wrench',
            role: 'SETTING',
            permission: 'R',
          },
          {
            name: 'Parametres généraux',
            url: '/app/settings/general',
            icon: 'fa fa-wrench',
            role: 'SETTING',
            permission: 'R',
          },
        ],
      },
    ];

    let ch = null;
    if (rPItems) {
      rPItems.forEach((m) => {

        if (m.title === true) {
          // find role
          if (m.roles) {
            roles.forEach((r) => {
              // console.log('rurrent item roles name', r.name);
              const length = m.roles.length;
              for (let x = 0; x < length; x++) {
                // console.log('rolr name', m.roles[x]);
                if (r.name === m.roles[x]) {
                  computedItems.push(
                    {
                      name: m.name,
                      title: true,
                    },
                  );
                  break;
                }
              }
            });
          }
        }
        let noAdd = false;
        let noAdd2 = false;
        const children = [];
        if (m.children) {
          m.children.forEach((c: any) => {
            if (roles) {
              roles.forEach((r) => {
                if (r.permissions) {
                  r.permissions.forEach((p) => {
                    if (r.name === c.role && p.name === c.permission) {
                      children.push({
                        name: c.name,
                        icon: c.icon,
                        url: c.url,
                        routeParams: c.routeParams,
                      });
                      noAdd = true;
                    }
                  });
                }
              });
            }
          });
        }
        if (children.length > 0) {
          ch = children;
        }
        // For single item
        if (roles) {
          roles.forEach((r) => {
            if (r.permissions) {
              r.permissions.forEach((p) => {
                if (r.name === m.role && p.name === m.permission) {
                  noAdd2 = true;
                }
              });
            }
          });
        }

        if (noAdd !== false || noAdd2 !== false) {
          computedItems.push(
            {
              name: m.name,
              icon: m.icon,
              url: m.url,
              children: ch,
            },
          );
        }

        // console.log = m;
      });

      // Remove names by filter
      localStorage.setItem('menuItems', JSON.stringify(computedItems));

      return computedItems;
    }
  }

  // Home menu items
  getUserHomeMenuItems() {
    let path: any[];
    this.authenticationService.path.subscribe(x => path = x);
    console.log('path', path);

    const availableGroupItems: any[] = [
      {
        groupName: 'Gestion des postes',
        role: 'JOB',
        items: [
          {
            name: 'Liste des postes',
            description: 'Visualiser les postes',
            url: '/app/security/jobs',
            permission: 'R',
          },
          {
            name: 'Ajouter un poste',
            description: 'Ajouter un poste',
            url: '/app/security/jobs/add',
            permission: 'C',
          },
        ],
      },
      {
        groupName: 'Gestion des utilisateurs',
        role: 'USER',
        items: [
          {
            name: 'Liste des utilisateurs',
            description: 'Visualiser les utilisateurs',
            url: '/app/security/users',
            permission: 'R',
          },
          {
            name: 'Ajouter un utilisateur',
            description: 'Ajouter un utilisateur',
            url: '/app/security/users/add',
            permission: 'C',
          },
        ],
      },
      {
        groupName: 'Gestion des clients',
        role: 'CUSTOMER',
        items: [
          {
            name: 'Liste des clients',
            description: 'Visualiser les clients',
            url: '/app/customers',
            permission: 'R',
          },
          {
            name: 'Ajouter un client',
            description: 'Ajouter un client',
            url: '/app/customers/add',
            permission: 'C',
          },
        ],
      },
    ];
    const items: any[] = [];
    availableGroupItems.forEach((availableGroupItem) => {
      const i = [];
      if (path[availableGroupItem.role]) {
        if (availableGroupItem.items) {
          // console.log('availableGroupItem.items', availableGroupItem.items);

          availableGroupItem.items.forEach((item) => {
            // console.log(availableGroupItem.role + item.permission, path[availableGroupItem.role + item.permission]);
            if (path[availableGroupItem.role + item.permission]) {
              i.push(item);
            }
          });
        }
        const tAGI = {
          groupName: availableGroupItem.groupName,
          items: i,
        };
        items.push(tAGI);
      }
    });
    // console.log(items);
    return items;
  }

  calcAge(date): number {
    const birthday: any = new Date(date);
    const result = Math.round((Date.now() - birthday) / (31557600000) );
    if (isNaN(result)) {
      return 0;
    } else {
      return result;
    }
  }

  initCurrentDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    const hh = today.getHours();
    let time = '';
    if (hh < 10) {
      time += '0' + hh + ':';
    } else {
      time += hh + ':';
    }
    const min = today.getMinutes();
    if (min < 10) {
      time += '0' + min;
    } else {
      time += min;
    }
    const date = yyyy + '-' + mm + '-' + dd;
    // console.log('time', tim
    //  cont time = '2:00';
    return date;
  }
  convertDateToString(theDate) {
    const dd = String(theDate.getDate()).padStart(2, '0');
    const mm = String(theDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = theDate.getFullYear();
    const hh = theDate.getHours();
    let time = '';
    if (hh < 10) {
      time += '0' + hh + ':';
    } else {
      time += hh + ':';
    }
    const min = theDate.getMinutes();
    if (min < 10) {
      time += '0' + min;
    } else {
      time += min;
    }
    const date = yyyy + '-' + mm + '-' + dd;
    // console.log('time', tim
    //  cont time = '2:00';
    return date;
  }

  convertDateToYYYYMMDD(dateStr) {
    let birthdateStr = dateStr
    if(dateStr) {
      let dArray = [];
      dArray = dateStr.split('-')
      if(dArray.length!=3) {
        dArray = dateStr.split('/')
      }
      if(dArray.length==3 && dArray[2].length == 4) {
        birthdateStr = dArray[2] + '/' + dArray[1] + '/' + dArray[0]
      }
    }
    return birthdateStr;
  }

  editDate(initialDate, params) {
    initialDate = new Date(initialDate);

    const year = params['year'];
    const month = params['month'];
    const day = params['day'];

    const date = initialDate;
    date.setFullYear(initialDate.getFullYear() + year);
    date.setMonth(initialDate.getMonth() + month);
    // date.setDate(initialDate.getDay() + day);
    const finalDate = this.convertDateToString(date);
    console.log('finalDate', finalDate);
    return finalDate;
  }

  initCurrentTime() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //  January is 0!
    const yyyy = today.getFullYear();
    const hh = today.getHours();
    let time = '';
    if (hh < 10) {
      time += '0' + hh + ':';
    } else {
      time += hh + ':';
    }
    const min = today.getMinutes();
    if (min < 10) {
      time += '0' + min;
    } else {
      time += min;
    }
    const date = yyyy + '-' + mm + '-' + dd;
    //  cont time = '2:00';
    return time;
  }

  getDaysInMonth(month, year) {
    // Les mois en JavaScript vont de 0 (janvier) à 11 (décembre)
    return new Date(year, month, 0).getDate();
  }

  formatDate(year, month, day, format) {
    const date = new Date(year, month - 1, day);
    const yearStr = date.getFullYear();
    const monthStr = ('0' + (date.getMonth() + 1)).slice(-2);
    const dayStr = ('0' + date.getDate()).slice(-2);

    switch (format) {
      case 'YYYY/MM/dd':
        return `${yearStr}/${monthStr}/${dayStr}`;
      case 'MM/dd/YYYY':
        return `${monthStr}/${dayStr}/${yearStr}`;
      case 'dd/MM/YYYY':
        return `${dayStr}/${monthStr}/${yearStr}`;
      default:
        throw new Error('Format non supporté');
    }
  }


  sum(args, param) {
    let total = 0;
    if (args) {
      args.forEach(arg => {
        // console.log('arg', arg);
        if (arg) {
          let n = 0;
          if (arg[param]) {
            n = Number(arg[param]);
          }
          if (n) {
            total += n;
          }
        }
      });
    }
    return total;
  }


  exportTableToExcel(elementId, name) {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //  January is 0!
    const yyyy = today.getFullYear();
    const hh = today.getHours();
    const min = today.getMinutes();
    const date = dd + '-' + mm + '-' + yyyy + ' ' + hh + 'H' + min + 'min';
    const tbl = document.getElementById(elementId);
    const wb = XLSX.utils.table_to_book(tbl);
    // console.log(wb);
    XLSX.writeFile(wb, name + ' ' + date + '.xlsx');

  }

  exportResource(resources, name='', fields = null) {
    const wb = XLSX.utils.book_new();
    // Filter data to export
    const resourceExport = [];
    resources.forEach((resource: any) => {
      const temp = {};
      for (const name in resource) {
        // if (resource[name] !== '') {
        if ((fields && fields[name]) || fields == null) {
          temp[name] = resource[name];
        }
      }
      resourceExport.push(temp);
    });
    const sheet = XLSX.utils.json_to_sheet(resourceExport);
    XLSX.utils.book_append_sheet(wb, sheet, 'resource');
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //  January is 0!
    const yyyy = today.getFullYear();
    const hh = today.getHours();
    const min = today.getMinutes();
    const date = dd + '-' + mm + '-' + yyyy + ' ' + hh + 'H' + min + 'min';
    XLSX.writeFile(wb, name + date + '.xlsx');
  }


  setContractText(settingData, enterprise, contract, showNumberToString) {
    let contractText = '';

    if (contract && settingData) {
      let contractDuration = '';
      if (contract.year_duration > 0) {
        contractDuration = contractDuration + contract.year_duration + ' an(s) ';
      }
      if (contract.month_duration > 0) {
        contractDuration = contractDuration + contract.month_duration + ' mois';
      }

      let capitalisationDuration = '';
      if (contract.capitalization_year > 0) {
        capitalisationDuration = capitalisationDuration + contract.capitalization_year + ' an(s) ';
      }
      if (contract.capitalization_month > 0) {
        capitalisationDuration = capitalisationDuration + contract.capitalization_month + ' mois';
      }
      let amountInString = '__________________________________________________';
      if (showNumberToString) {
        amountInString = this.numberStringValue('FR', contract.subscription_amount);
      }

      let remunarationAmountInString = '__________________________________________________';
      if (showNumberToString) {
        remunarationAmountInString = this.numberStringValue('FR', contract.remuneration);
      }
      let goodName = '';
      if (contract.good && contract.good.name) {
        goodName = contract.good.name;
      }
      const contractTextParams = [
        {
          name: '@NOM_RESPONSABLE@',
          value: settingData['CONTRACTOR_NAME']
        },
        {
          name: '@NOM_CLIENT@',
          value: contract.customer.firstname + ' ' + contract.customer.lastname,
        },
        {
          name: '@NOM_BIEN@',
          value: goodName,
        },
        {
          name: '@DEVISE@',
          value: settingData['CURENCY_NAME'],
        },
        {
          name: '@MONTANT@',
          value: this.formatNumber.transform(contract.subscription_amount),
        },
        {
          name: '@MONTANT_EN_LETTRE@',
          value: amountInString,
        },
        {
          name: '@MONTANT_EN_LETTRE_MANUEL@',
          value: '__________________________________________________',
        },
        {
          name: '@MONTANT_GAIN@',
          value: this.formatNumber.transform(contract.remuneration),
        },
        {
          name: '@MONTANT_GAIN_EN_LETTRE@',
          value: remunarationAmountInString,
        },
        {
          name: '@MONTANT_GAIN_EN_LETTRE_MANUEL@',
          value: '__________________________________________________',
        },
        {
          name: '@POURCENTAGE_PRODUIT@',
          value: contract.percentage,
        },
        {
          name: '@DUREE_CONTRAT@',
          value: contractDuration,
        },
        {
          name: '@DUREE_CAPITALISATION@',
          value: capitalisationDuration,
        },
        {
          name: '@DATE_DEBUT@',
          value: contract.from_date,
        },
        {
          name: '@DATE_FIN@',
          value: contract.to_date,
        },
        {
          name: '@POURCENTAGE_RETOUR_CAPITAL@',
          value: contract.return_capital_percentage,
        },
        {
          name: '@MONTANT_RETOUR_CAPITAL@',
          value: this.formatNumber.transform(contract?.subscription_amount * contract?.return_capital_percentage / 100),
        },
        {
          name: '@DATE_CONTRAT@',
          value: contract?.date,
        },
        {
          name: '@LOCALISATION_ENTREPRISE@',
          value: enterprise?.location,
        },

      ];

      contractText = contract.product.contract_text;
      contractTextParams.forEach(element => {
        // console.log('element', element);
        let canReplace = true;
        while (canReplace) {
        contractText = contractText.replace(element.name, element.value);
          if (!contractText.includes(element.name)) {
            canReplace = false;
          }
        }
      });
    }
    return contractText;

  }

  setPaymentSliceReceiptText(settingData, enterprise, paymentSlice, showNumberToString) {
    console.log('enterprise', enterprise);
    console.log('paymentSlice', paymentSlice);
    let receiptText = 'Aucun texte';
    let paymentUserName = '';
    let checkoutName = '';
    if (paymentSlice.payment_user && paymentSlice.payment_user.name) {
      paymentUserName = paymentSlice.payment_user.name;
    }
    if (paymentSlice.checkout && paymentSlice.checkout.name) {
      checkoutName = paymentSlice.checkout.name;
    }
    if (paymentSlice) {
      const contract = paymentSlice.contract;
      if (paymentSlice.contract && settingData) {
        let remunarationAmountInString = '__________________________________________________';
        if (showNumberToString) {
          remunarationAmountInString = this.numberStringValue('FR', paymentSlice.amount);
        }
        const receiptTextParams = [
          {
            name: '@NOM_RESPONSABLE@',
            value: settingData['CONTRACTOR_NAME']
          },
          {
            name: '@NOM_CLIENT@',
            value: contract.customer.firstname + ' ' + contract.customer.lastname,
          },
          {
            name: '@DEVISE@',
            value: settingData['CURENCY_NAME'],
          },
          {
            name: '@MONTANT@',
            value: this.formatNumber.transform(contract.subscription_amount),
          },
          {
            name: '@MONTANT_GAIN_EN_LETTRE@',
            value: remunarationAmountInString,
          },
          {
            name: '@MONTANT_EN_LETTRE_MANUEL@',
            value: '__________________________________________________',
          },
          {
            name: '@MONTANT_GAIN@',
            value: this.formatNumber.transform(paymentSlice.amount),
          },
          {
            name: '@MONTANT_GAIN_EN_LETTRE@',
            value: remunarationAmountInString,
          },
          {
            name: '@MONTANT_GAIN_EN_LETTRE_MANUEL@',
            value: '__________________________________________________',
          },
          {
            name: '@POURCENTAGE_PRODUIT@',
            value: contract.percentage,
          },
          {
            name: '@NUMERO_CONTRAT@',
            value: contract.number,
          },
          {
            name: '@DATE_PAIEMENT@',
            value: paymentSlice.date,
          },
          {
            name: '@DATE_CONTRAT@',
            value: contract?.date,
          },
          {
            name: '@LOCALISATION_ENTREPRISE@',
            value: enterprise?.location,
          },
          {
            name: '@CAISSIER@',
            value: paymentUserName,
          },
          {
            name: '@CAISSE@',
            value: checkoutName,
          },
        ];
        if (settingData['RECEIPT_DEFAULT_TEXT']) {
          receiptText = settingData['RECEIPT_DEFAULT_TEXT'];
        }
        receiptTextParams.forEach(element => {
          // console.log('element', element);
          let canReplace = true;
          while (canReplace) {
          receiptText = receiptText.replace(element.name, element.value);
            if (!receiptText.includes(element.name)) {
              canReplace = false;
            }
          }
        });
      }
    }
    return receiptText;
  }
  getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => {
      formData.append(key, object[key])

      // Ne pas faire de controle pour formater (stringfy) les objets car File() pour les fichiers pourraien perdre leur etat
      /*
      if(this.getType(object[key]) != 'object') {
        formData.append(key, object[key])
      }
      if(this.getType(object[key]) == 'object') {
        formData.append(key, JSON.stringify(object[key]))
      }
      */
    });
    return formData;
  }

  addPrefix(object, prefix) {
    const data = {};
    Object.keys(object).forEach(key => data[prefix + key] = object[key]);
    return data;
  }
  addPrefixToArray(array, prefix) {
    const result = [];
    array.forEach(object => {
      const data = {};
      Object.keys(object).forEach(key => {
        if(!key.startsWith('embed_')) {
          data[prefix + key] = object[key]
        } else {
          data[key] = object[key]
        }
      });
      result.push(data);
    });
    return result
  }
  getMultipleToFile(object, fileItem, folder, fileName, resourceKey, resourceKeyValue) {
    let data = {};
    Object.keys(object).forEach(key => {
      const fileData = {}
      fileData[fileItem] = object[fileItem]
      const fileInfo = {folder: folder, name: fileName}
      const resourceInfo = {resourceKey, resourceKeyValue}
      const fileObj = this.formatImage(fileData, fileItem, fileInfo, resourceInfo);
      data = {...data, ...fileObj}
    });
    return data
  }
  getEmbedData(object) {
    const prefix = 'embed_'
    const data = {};
    Object.keys(object).forEach(key => data[prefix + key] = JSON.stringify(object[key]));
    return data;
  }

  getResourceData(object) {
    const prefix = 'resources_'
    const data = {};
    Object.keys(object).forEach(key => data[prefix + key] = JSON.stringify(object[key]));
    return data;
  }

  formatImage(object, key, {folder, name}, resourceInfo = {}) {
    const data = {};
    // && typeof object[key] == 'string'
    if(object[key]) {
      const prefix = 'file_';
      data[prefix + key] = object[key]
      data[prefix + key + '_paramField'] = prefix + key
      data[prefix + key + '_paramName'] = name
      data[prefix + key + '_paramResourceItem'] = key
      data[prefix + key + '_paramFolder'] = folder
      data[prefix + key + '_paramResourceKey'] = resourceInfo['resourceKey']
      data[prefix + key + '_paramResourceKeyValue'] = resourceInfo['resourceKeyValue']
    }
    return data;
  }

  getType(el) {
    return typeof el;
  }

  createId(): string {
    let id = '';
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  }

  getProductUnitQuantity(quantity, units, type='string') {
    console.log('units', units)
    const unitObj = {}
    let quantityString = '';
    let remainingQuantity = quantity;
    if (units && units.length > 0) {
      units = units.sort((a, b) => {
        if (a.quantity < b.quantity) {
          return 1;
        } else {
          return - 1;
        };
        return 0;
      });
    }
    if (units) {
      units.forEach(unit => {
        const unitQ = Math.trunc(remainingQuantity / unit.quantity);
        remainingQuantity = remainingQuantity - (unitQ * unit.quantity);
        if (unitQ > 0) {
          let unitName = '';
          if (unit.product_unit) {
            unitName = unit.product_unit.name;
          }
          quantityString += unitQ + ' ' + unitName + ' ';
          if (unit.product_unit) {
            unitObj[unit.product_unit.id] = unitQ
          }
        } else {

        }
      });
    }
    console.log('quantityString', quantityString);
    if(type == 'object') {
      return unitObj;
    }
    return quantityString;
  }

  sortProductUnits(units) {
    console.log('units', units)
    if (units && units.length > 0) {
      units = units.sort((a, b) => {
        if (a.quantity < b.quantity) {
          return 1;
        } else {
          return - 1;
        };
        return 0;
      });
    }
    return units;
  }

  getDataGroupBy(resources: any, field: any, groupParams: any = {}, subGroups = []) {
    // console.log('getDataGroupBy')
    let dataGroupBy: any[] = []
    const fieldList = this.getSuResources(resources, field)
    // console.log('fieldList', fieldList)
    fieldList.forEach(suResource => {
      let data: any[] = []
      if (suResource) {
        if(typeof suResource == 'string') {
          data = resources.filter((resource: any) => {
            // console.log('this.getResourceItem(resource, field)', this.getResourceItem(resource, field, true))
            // console.log('suResource', suResource)
            return this.getResourceItem(resource, field, true) == suResource
          })
        } else {
          data = resources.filter((resource: any) => {
            // console.log('this.getResourceItem(resource, field)', this.getResourceItem(resource, field, true))
            // console.log('suResource[field.key]', suResource[field.key])
            return this.getResourceItem(resource, field, true) == suResource[field.key]
          })
        }

        // In case subResource in a string(not object) we return an object based on returnedObjKey
        if(typeof suResource == 'string') {
          const tempSubResource: any = {}
          tempSubResource[field.returnedObjKey] = suResource
          suResource = tempSubResource
        }

        console.log('fieldListTemp', fieldList)
        if (groupParams['COUNT']?.value) {
          suResource['dataCount'] = data.length
        }
        if (groupParams['LIST']?.value) {
          suResource['dataList'] = data
        }
        if (groupParams['SUM']?.value) {
          groupParams['SUM']?.fields?.forEach((field: any) => {
            suResource[field + 'DataSum'] = this.sum(data, field)
          });
        }
        subGroups.forEach((subGroup: any) => {
          // console.log('subGroup', subGroup)
          suResource[subGroup?.field?.name + 'Group'] = this.getDataGroupBy(data, subGroup?.field, subGroup.groupParams)
        })
        dataGroupBy.push(suResource)
      }

    })

    return dataGroupBy
  }

  getResourceItem(resource: any, field: any, returKey = false) {
    let item: any = null
    if (!field.path) {
      item = resource?.[field.name]
      if (returKey && field.key) {
        item = item?.[field.key]
      }
    } else {
      const pathData = field.path?.split('/')
      item = resource
      pathData.forEach((path: string) => {
        item = item?.[path]
      })
      if (returKey && field.key) {
        item = item?.[field.key]
      }
    }
    return item
  }

  getSuResources(resources: any[], field: any) {
    const subResources: any[] = []
    resources.forEach(resource => {
      const subResource = this.getResourceItem(resource, field);
      // console.log('subResource', subResource)
      if (subResource) {
        if(typeof subResource != 'string') {
          const already = subResources.find(subResourceTemp => subResourceTemp[field.key] == subResource?.[field.key])
          if (!already) {
            subResources.push(subResource)
          }
        } else {
          const already = subResources.find(subResourceTemp => subResourceTemp == subResource)
          if (!already) {
            subResources.push(subResource)
          }
        }
      }
    })
    return subResources;
  }
  getRandomColors(length) {
    const colors = []
    while(colors.length < length) {
      const randomColor = Math.floor(Math.random()*16777215).toString(16);
      const randomColorString = '#' + randomColor
      const oldColor = colors.find(x => x == randomColorString)
      if(!oldColor) {
        colors.push(randomColorString)
      }
      // console.log('randomColor', randomColor)
    }
    console.log('colors', colors)
    return colors
  }

  updateFile(event, resourceForm, formControlName) {
    console.log('updateFile');
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      resourceForm.get(formControlName).setValue(file);
    }
  }
}
