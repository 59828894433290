import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment';



let agency = null;
try {
  const temp = JSON.parse(localStorage.getItem('agency'));
  const tempAgency = temp[environment.appName][environment.appCode];
  console.log('agency', temp);

  if (tempAgency) {
    agency = tempAgency;
  }
} catch (e) {
}

/*
let currentUser = null;
try {
  const temp = JSON.parse(localStorage.getItem('currentUser'));
  const tempCurrentUser = temp[environment.appName][environment.appCode];
  console.log('currentUser', temp);
  if (tempCurrentUser) {
    currentUser = tempCurrentUser;
  }
} catch (e) {
}
*/

let userId = '';
try {
  const temp = JSON.parse(localStorage.getItem('currentUser'));
  const user = temp[environment.appName][environment.appCode];
  if (user) {
    userId = user.id;
  }
} catch (e) {
}

export class RequestInterceptor implements HttpInterceptor {
  token = null;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {

    try {
      const temp = JSON.parse(localStorage.getItem('token'));
      const tempToken = temp[environment.appName][environment.appCode];
      //  console.log('temp', temp);
      if (tempToken) {
        this.token = tempToken;
      }
    } catch (e) {
    }

    const appCode = environment.appCode;
    const body = req.body;
    const urlWithParams = req.urlWithParams;
    if (body) {
      if (body instanceof FormData) {
        body.append('enterprise_code', appCode);
        if (agency) {
          body.append('agency_id', agency.id);
        }
      } else {
        body['enterprise_code'] = appCode;
        if (agency) {
          body['agency_id'] = agency.id;
        }

      }
    }

    if (req.headers.get('skip') == 'true') {
      console.log('skip');
      req = req.clone({
        headers: req.headers.delete('skip'),
        body,
      });
      return next.handle(req);
    } else {
      req = req.clone(
        {
          headers: this.setHeaders(req.headers),
          body,
        },
      );
    }
     console.log('req', req);
    //  console.log('body', body);
     //  console.log('req.headers', req.headers);

    return next.handle(req);
  }

  setHeaders(headers): HttpHeaders {
    headers = headers.set('Accept', 'application/json');
    if (this.token) {
       console.log('this.token', this.token);
      headers = headers.set('Authorization', `Bearer ${this.token}`);
      return headers;
    }
  }
}
