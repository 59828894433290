import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppApiUserService } from '../../services/app-api/app-api-user.service';
import { AuthenticationService } from '../../services/authentication.service';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']

})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  remainingTime: string;
  tempLoginRemaining: any;
  agencyId: number;
  userSubscription: Subscription;
  user: any;
  backendUrl: string = environment.backendUrl;
  sessionTime: string;
  public sidebarMinimized = false;
  public navItems;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private generalService: GeneralService,
    private userService: AppApiUserService,
  ) {

  }
  ngOnDestroy(): void {
    clearInterval(this.tempLoginRemaining);
  }
  ngOnInit() {
    const agency = this.authenticationService.getLocalStorageItem('agency');
    if (agency) {
      this.agencyId = agency.id;
    }
    this.userSubscription = this.userService.resourceSubject.subscribe(
      (user: any) => {
        console.log('new', user);
        this.user = user;
      },
    );
    this.navItems = this.generalService.getAppRoutes();
    console.log('this.navItems', this.navItems);
    this.authenticationService.currentUser.subscribe(x => {
      // console.log('currentUser', x)
      this.user = x;
      if (this.user) {
        this.userService.getResource(this.user.id);
      }
    });
    this.getRemainingTime();
  }
  onAgencyChange() {
    console.log('agencyId', this.agencyId);
    if (this.user) {
      const agency = this.user.agencies.find(x => x.id == this.agencyId);
      console.log('agency', agency);
      this.authenticationService.setLocalStorageItem('agency', agency);
    }
  }
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  logout() {
    this.router.navigate(['auth/logout']);
  }
  profile() {
    this.router.navigate(['app/profile']);
  }
  getRemainingTime() {
    this.tempLoginRemaining = setInterval( () => {
      this.remainingTime = this.authenticationService.getLoginRemainingTime();
      this.sessionTime = this.authenticationService.getSessionStartTime();
    }, 1000);
  }
}
