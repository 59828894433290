export const environment = {
  appName: 'SMP',
  appCode: 'EBT',
  editType: 'modal',
  production: true,
  backendUrl: 'https://ets-togosime.com',
  useProductUnit: false,
  useProductStock: false,
  useGeneralBoxDelivery: false,
};
