import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ResourceActionService extends CrudService {
  resourceEditType = '';
  resourcesSubject = new Subject<any[]>();
  resources: any[] = [];
  resourcesDataSubject = new Subject<any>();
  resourcesData: any;
  resourceSubject = new Subject<any>();
  resource: any;
  resourceActionResultSubject = new Subject<any>();
  resourceActionResult: any = {
   /*
    type: '',
    message: 'success',
    */
  };
  currentResourceSubject = new Subject<any>();
  currentResource: any = {};
  constructor(public http: HttpClient) {
    super(http);
  }
  emitResourceActionResult() {
    this.resourceActionResultSubject.next(this.resourceActionResult);
  }
  emitResourcesSubject() {
    if(Array.isArray(this.resources)) {
      this.resourcesSubject.next(this.resources.slice());
    } else {
      this.resourcesSubject.next(this.resources);
    }
  }
  emitResourcesDataSubject() {
    if(Array.isArray(this.resourcesData)) {
      this.resourcesDataSubject.next(this.resourcesData);
    } else {
      this.resourcesDataSubject.next(this.resourcesData);
    }
  }
  getSimpleResources(params = {}) {
    return this.getAll(params);
  }
  getResources(params = {}) {
    this.getAll(params).subscribe(
      (result: any) => {
        // console.log('resources se', result.data);
        if(Array.isArray(result.data)) {
          this.resources = result.data;
        } else if(result.data) {
          this.resources = result.data;
        } else if(Array.isArray(result)) {
          this.resources = result;
        }
        this.emitResourcesSubject();
      },
      (error) => {

      }
    );
  }

  getResourcesData(params = {}, code=null) {
    this.getAll(params).subscribe(
      (result: any) => {
        // console.log('resources se', result.data);
        let resources: any[] = []
        if(Array.isArray(result.data)) {
          resources = result.data;
        } else if(result.data) {
          resources = result.data;
        } else if(Array.isArray(result)) {
          resources = result;
        }
        this.resourcesData  = {
          code,
          data: resources
        }
        this.emitResourcesDataSubject();
      },
      (error) => {

      }
    );
  }

  emitResourceSubject() {
    this.resourceSubject.next(this.resource);
  }
  getResource(id, params = {}) {
    this.get(id, params).subscribe(
      (resource: any) => {
        this.resource = resource;
        this.emitResourceSubject();
      },
    );
  }

  addResource(resourceObj: any) {
    console.log('resourceObj serv', resourceObj);
    this.resourceActionResult = {
      type: 'add',
      message: '',
      data: {},
    };
    this.add(resourceObj).subscribe(
      (resource: any) => {
        //  this.resources.unshift(resource);
        //  this.emitResourcesSubject();
        this.resourceActionResult.message = 'success';
        let data = resource;
        if(resource.data){
          data = resource.data;
        }
        this.resourceActionResult.data = data;
        this.emitResourceActionResult();
      },
      (error) => {
        this.resourceActionResult.message = 'failure';
        this.resourceActionResult.data = error;
        console.log('error', error);
        this.emitResourceActionResult();
      },
    );
  }
  updateResource(id: number, resource: any) {
    this.update(id, resource).subscribe(
      (resourceResponse: any) => {
        let index;
        //  this.getResource(id);
        if (this.resources.length > 0) {
            index = this.resources.findIndex(
            (item: any, i) => {
              if(item){
                return item.id === id;
              }
            },
          );
          console.log('index='+index);
          this.resources[index] = resourceResponse;
        }
        this.resourceActionResult = {
          type: 'update',
          message: 'success',
        };
        this.emitResourceActionResult();
      },
    );
  }

  updateResource2(id: number, resource: any) {
    this.update2(id, resource).subscribe(
      (resourceResponse: any) => {
        let index;
        //  this.getResource(id);
        if (this.resources.length > 0) {
            index = this.resources.findIndex(
            (item: any, i) => {
              if(item){
                return item.id === id;
              }
            },
          );
          console.log('index=' + index);
          this.resources[index] = resourceResponse;
        }
        this.resourceActionResult = {
          type: 'update',
          message: 'success',
        };
        this.emitResourceActionResult();
      },
    );
  }

  deleteResource(id, params={}) {
    console.log('service delete ministere');
    this.resourceActionResult = {
      type: 'delete',
      message: 'success',
    };
    this.delete(id).subscribe(
      (resource: any) => {
        this.getResources(params);
        this.emitResourceActionResult();
      },
      (error) => {
        this.resourceActionResult.message = 'failure';
        console.log('error');
        this.emitResourceActionResult();
      },
    );
  }
  addAllResource(input) {
    this.resourceActionResult = {
      type: 'add_all',
      message: 'success',
    };
    this.addAll(input).subscribe(
      (resource: any) => {
        this.getResources();
        this.emitResourceActionResult();
      },
      (error) => {
        this.resourceActionResult.message = 'failure';
        this.resourceActionResult.data = error;
        this.emitResourceActionResult();

        console.log('error');
      },
    );
  }
  uploadResourceImport(input) {
    this.resourceActionResult = {
      type: 'import',
      message: 'success',
    };
    this.addAll(input).subscribe(
      (resource: any) => {
        this.getResources();
        this.emitResourceActionResult();
      },
      (error) => {
        this.resourceActionResult.message = 'failure';
        this.emitResourceActionResult();

        console.log('error');
      },
    );
  }
  importResource(input) {
    this.resourceActionResult = {
      type: 'import_data',
      message: 'success',
    };
    this.import(input).subscribe(
      (resource: any) => {
        this.getResources();
        this.emitResourceActionResult();
      },
      (error) => {
        this.resourceActionResult.message = 'failure';
        this.emitResourceActionResult();

        console.log('error');
      },
    );
  }
}
