import { GeneralService } from './../services/general.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberStringValue',
})
export class NumberStringValuePipe implements PipeTransform {
  constructor(private generalService: GeneralService) {}
  transform(value: any, ...args: any[]): any {
    return this.generalService.numberStringConvert('FR', value);
  }

}
